/*
 *⽤于存放整个项⽬的全局配置
*/
// lmc.dev.admin.gonghuolianmeng.com
// TESING: 'https://lmc.dev.api.gonghuolianmeng.com/api_v1',
//   PRODUCTION: 'https://www.gonghuolianmeng.com/api_v1',
//   DEVELOPMENT: 'https://lmc.dev.api.gonghuolianmeng.com/api_v1',
// export const APP_KEY = '71632d6c6d63616e672d3031313932303430';
const BASE_URL_ENV = {
  TESING: 'https://lmc.dev.admin.gonghuolianmeng.com/api_v1',
  PRODUCTION: 'https://lmc.dev.admin.gonghuolianmeng.com/api_v1',
  DEVELOPMENT: 'https://lmc.dev.admin.gonghuolianmeng.com/api_v1',
}

const { REACT_APP_RUNTINE_ENV: REACT_ENV } = process.env;
const RUNTIME_ENV = REACT_ENV && REACT_ENV !== ""
  ? REACT_ENV
  : (process.env.NODE_ENV as string).toLocaleUpperCase();

export const BASE_URL = BASE_URL_ENV[RUNTIME_ENV as keyof typeof BASE_URL_ENV]

export const APP_KEY = '71632d6c6d63616e672d3031313932303430';
