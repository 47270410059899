/*
 * @Author: Yoneyy (y.tianyuan)
 * @Date: 2022-08-24 09:34:12
 * @Last Modified by: Yoneyy (y.tianyuan)
 * @Last Modified time: 2024-01-09 10:36:14
 */

import { useCallback, useState } from 'react';
import { useMount, useDestroy } from '.';
import axios, { AxiosRequestConfig, Method, CancelTokenSource } from 'axios';

export type UseFetchOptions<P> = Omit<AxiosRequestConfig, 'url'> & {
  method?: Method;
  params?: P;
  data?: P;
};

export type UseFetchConfigs<S, P> = {
  once?: boolean;
  manual: boolean;
  onResponse?: (data: S, params: P) => void;
  onFail?: (error: Error) => void;
}

export type UseFetchResult<S, P> = {
  error: Error | undefined;
  loading: boolean;
  data: S | undefined;
  run: (opts: UseFetchOptions<P>) => void;
}

/**
 * useFetch
 * @param url request URL
 * @param options request config
 * @param configs useFetch configs `manual` is manual default `false`, `ready` default `ready` trigger request
 * @returns
 * @author yoneyy (y.tianyuan)
 */
function useFetch<T, P = Record<string, unknown>, S = CustomResponse<T>>(
  url: string = '',
  options: UseFetchOptions<P> = {},
  configs: UseFetchConfigs<S, P> = { manual: false }
): UseFetchResult<S, P> {
  const [data, setData] = useState<S>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(false);

  const run = (opts: UseFetchOptions<P> = options) => configs.manual && request(opts);

  const request = useCallback((opts: typeof options) => {
    const rOptions = Object.assign(opts, options)
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true);
    axios({
      url,
      ...rOptions,
      cancelToken: source.token
    }).then(({ data }) => {
      setData(data);

      configs.manual
        && configs?.onResponse?.(data, rOptions?.params ?? rOptions?.data ?? {});
    })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setError(error);
          configs?.onFail?.(error);
        }
      })
      .finally(() => setLoading(false));
    return source;
  }, [url, options, configs]);

  let source: CancelTokenSource;

  useMount(() => {
    if (!configs.manual || configs.once === true)
      source = request(options);
  });

  useDestroy(() => {
    if (!configs.manual) source.cancel();
  });

  return { error, loading, data, run };
}

export default useFetch;