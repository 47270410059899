import { useRoutes, RouteObject } from "react-router-dom";
import React, { Suspense, lazy } from 'react'
import Home from "../pages/Home";
import Login from "../pages/Login";
import Forget from "../pages/Login/forget";

import { Spin } from "antd";
const HomePage = lazy(() => import("../pages/HomePage"));
const AuthorizedAgent = lazy(() => import("../pages/AuthorizedAgent"));
const ExpressDeliveryReview = lazy(() => import("../pages/ExpressDeliveryReview"));
const FuntionManage = lazy(() => import("../pages/FuntionManage"));
const RealNameAuditing = lazy(() => import("../pages/RealNameAuditing"));
const MemberReview = lazy(() => import("../pages/MemberReview"));
const ServeView = lazy(() => import("../pages/ServeMarket/serveView"));
const ClassView = lazy(() => import("../pages/ServeMarket/classView"));
const FuntionEditDetail = lazy(() => import("../pages/FuntionManage/editDetail"));

export type IRouter = RouteObject & {};

/**
 * @description 路由懒加载
 * @param {Element} Comp 需要访问的组件
 * @returns element
 */

const lazyLoad = (Comp: JSX.Element) => {
  return (
    <Suspense
      fallback={
        <Spin
          size="large"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
          }}
        />
      }
    >
      {Comp}
    </Suspense>
  );
};

export const rootRouter: IRouter[] = [
  {
    path: "/",
    element: <Home />,
    children: [
      {
        index: true,
        path: '/',
        element: lazyLoad(<HomePage />)
      },
      {
        path: '/memberReview',
        element: lazyLoad(<MemberReview />)
      },
      {
        path: '/expressDeliveryReview',
        element: lazyLoad(<ExpressDeliveryReview />)
      },
      {
        path: '/funtionManage',
        children: [
          {
            path: '/funtionManage',
            element: lazyLoad(<FuntionManage />),
          },
          {
            path: '/funtionManage/edit',
            element: lazyLoad(<FuntionEditDetail />),
          }
        ]
      },
      {
        path: '/authorizedAgent',
        element: lazyLoad(<AuthorizedAgent />)
      },
      {
        path: '/realNameAuditing',
        element: lazyLoad(<RealNameAuditing />)
      },
      {
        path: '/serveMarket',
        children: [
          {
            path: '/serveMarket/classsView',
            element: lazyLoad(<ClassView />)
          },
          {
            path: '/serveMarket/serveView',
            element: lazyLoad(<ServeView />)
          }
        ]
      },
    ]
  },
  {
    path: "/sign",
    children: [
      {
        path: "login",
        element: lazyLoad(<Login />)
      },
      {
        path: 'forget',
        element: lazyLoad(<Forget />)
      }
    ]
  }
];

const Router = () => {
  return useRoutes(rootRouter);
};

export default Router;
