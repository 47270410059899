/*
 * @Author: Yoneyy (y.tianyuan)
 * @Date: 2022-03-02 22:10:02
 * @Last Modified by: Yoneyy (y.tianyuan)
 * @Last Modified time: 2022-08-24 09:56:09
 */

import { useEffect } from "react";


/**
 * useDestroy
 * @param callback
 * @description 当页面销毁时
 * @author yoneyy (y.tianyuan)
 */
const useDestroy = (
  callback: (...rest: unknown[]) => void
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => callback(), [])
}

export default useDestroy;