import { configureStore } from '@reduxjs/toolkit';

//引入子模块
import counterFile from './modules/count';

const store = configureStore({
  reducer: {
    counterd: counterFile,
  }
})

export default store;