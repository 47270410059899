// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#layout-home .demo-logo-vertical {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
#layout-home .meun-logo {
  height: 24px;
}
#layout-home .meun-long {
  display: flex;
  align-items: center;
  height: 42px;
}
#layout-home .meun-long .meun-title {
  margin-left: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #fff;
}
#layout-home .layout-header {
  display: flex;
  justify-content: space-between;
  background: #0179D8;
}
#layout-home .layout-header .account-title {
  margin-right: 40px;
  color: #fff;
}
#layout-home .btn-out {
  background: transparent;
  color: #fff;
  width: 80px;
  height: 32px;
  padding: 0;
  line-height: 30px;
  font-size: 13px;
  font-weight: 600;
  font-family: Segoe UI, Segoe UI;
  border-color: rgba(255, 255, 255, 0.5);
}
#layout-home .btn-out:hover {
  color: #0179D8;
  border-color: #0179D8;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/styles/index.less"],"names":[],"mappings":"AAAA;EAGI,aAAA;EACA,uBAAA;EACA,eAAA;AADJ;AAJA;EASI,YAAA;AAFJ;AAPA;EAYI,aAAA;EACA,mBAAA;EACA,YAAA;AAFJ;AAZA;EAgBM,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;AADN;AAnBA;EAwBI,aAAA;EACA,8BAAA;EACA,mBAAA;AAFJ;AAxBA;EA4BM,kBAAA;EACA,WAAA;AADN;AA5BA;EAiCI,uBAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,+BAAA;EACA,sCAAA;AAFJ;AAGI;EACE,cAAA;EACA,qBAAA;AADN","sourcesContent":["#layout-home {\n  .demo-logo-vertical {\n    // background-color: #fff;\n    display: flex;\n    justify-content: center;\n    padding: 10px 0;\n\n  }\n  .meun-logo {\n    height: 24px;\n  }\n  .meun-long {\n    display: flex;\n    align-items: center;\n    height: 42px;\n    .meun-title {\n      margin-left: 4px;\n      font-size:14px;\n      font-weight: 600;\n      line-height: 14px;\n      color: #fff;\n    }\n  }\n  .layout-header {\n    display: flex;\n    justify-content: space-between;\n    background: #0179D8;\n    .account-title {\n      margin-right: 40px;\n      color: #fff;\n    }\n  }\n  .btn-out {\n    background: transparent;\n    color: #fff;\n    width: 80px;\n    height: 32px;\n    padding: 0;\n    line-height: 30px;\n    font-size: 13px;\n    font-weight: 600;\n    font-family: Segoe UI, Segoe UI;\n    border-color: rgba(255, 255, 255, 0.5);\n    &:hover {\n      color: #0179D8;\n      border-color: #0179D8;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
