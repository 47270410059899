/*
 * @Author: Yoneyy (y.tianyuan)
 * @Date: 2022-05-20 17:38:30
 * @Last Modified by: Yoneyy (y.tianyuan)
 * @Last Modified time: 2024-01-16 17:03:40
 */

import React, { ReactNode, forwardRef, ForwardRefRenderFunction } from "react";

export interface TextProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  children?: ReactNode;
  defaultText?: ReactNode;
  mode?: 'loose' | 'strict';
  display?: React.CSSProperties['display'];
  select?: React.CSSProperties['userSelect'];
}

const Text: ForwardRefRenderFunction<HTMLSpanElement, TextProps> = ({
  style,
  select,
  children: text,
  mode = 'loose',
  defaultText = '—',
  display = 'inline',
  ...props
}, ref) => {

  return (
    <span ref={ref} style={{
      display,
      userSelect: select,
      ...style,
    }} {...props}>
      {
        text == null
          || text === ''
          || (mode === 'strict' && (['null', 'undefined', 'Invalid date'].includes(text as string)))
          ? defaultText
          : text
      }
    </span>
  )
}

export default forwardRef(Text);