import { ConfigProvider } from "antd";
import { connect } from "react-redux";
import { message } from "antd";
import { MD5 } from "crypto-js";
import { BASE_URL, APP_KEY } from '../src/config';
import axios from "axios";
import Router from "./routes";
import "./assets/styles/rest.less";

axios.defaults.baseURL = BASE_URL;

const App = (props: any) => {
  const { assemblySize } = props;

  //请求拦截器
  axios.interceptors.request.use(
    (config) => {
      const ts = Math.floor(Date.now() / 1000);
      const { method, params, data: body } = config;
      const query = {
        ts,
        app_key: APP_KEY,
        ...params,
      };
      const qs: string[] = [];
      const keys = Reflect.ownKeys(query).sort();

      for (const k of keys) qs.push(`${k as string}=${query[k]}`);
      if (method?.toLocaleUpperCase() === "POST") {
        const data = JSON.stringify(body);
        qs.push(`_param=${data.replace(/\s+/gi, "")}`);
      }
      const sign = MD5(qs.join("&"));
      config.params = query;
      config.headers["X-Req-Sign"] = sign;
      config.headers['authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRoIjoie1widXNlcl9pZFwiOlwiYTM4YmE1NzktNzNhZi00N2JjLWI1MTItYjgxMzY1ZDNlYzMwXCIsXCJ1c2VyX3R5cGVcIjpcIk1BSU5fVVNFUlwifSIsImV4cCI6MTcxMjU1NjQwNSwibmJmIjoxNzExOTUxNjA1LCJpYXQiOjE3MTE5NTE2MDV9.jdwuyb487zVR9Ckf1DVpTWzQOE9x0Sf896F39i97ob0';
      const authStorage = localStorage.getItem('auth:token');
      if (authStorage) {
        config.headers["authorization_admin"] = "Bearer " + authStorage;
        // config.headers['authorization'] = "Bearer "+authStorage;
      }

      // config.headers["Content-Type"] = method === 'put' ? "multipart/form-data" : config.headers["Content-Type"];
      config.headers["Content-Type"] = method === 'put' ? "multipart/form-data" : config.headers["Content-Type"];

      // config.headers['X-Forwarded-For'] = '1.203.98.10'
      return config;
    },
    (error) => {
      // 对请求错误做些什么
      console.error(error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      // 对响应数据做点什么
      if (response.data.code === 401) {
        message.error(response.data.msg);
        window.top?.location.assign('/sign/login');
      }
      return response;
    },
    function (error) {
      // 对响应错误做点什么
      console.error('Error:', error);
      return Promise.reject(error);
    }
  );

  return (
    <ConfigProvider componentSize={assemblySize}>
      <Router></Router>
    </ConfigProvider>
  );
};

export default connect()(App);
