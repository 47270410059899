import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, Layout, Row, Col, message } from "antd";
import { MD5 } from "crypto-js";
import { base } from "../../service";
import { View } from "../../components";
import { useFetch } from "../../hooks";
import Headers from "./components/headers";
import "./style/forget.less";

const { Content } = Layout;
export interface ForgetProps { }

const Forget: FC<ForgetProps> = () => {
  const [countdjs, setcountdjs] = useState(true);
  const [count, setCount] = useState(60);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  message.config({
    top: 8,
    duration: 1,
    maxCount: 1,
    rtl: true,
    prefixCls: 'forget-message',
  });

  const { run: getverCode } = useFetch(
    base.GET_SMS_CODE,
    {},
    {
      manual: true,
      onResponse(res) {
        if (res.code === 200) {
          message.success({
            content: "发送成功",
            className: "register-message-success",

          })
        } else {
          message.error({
            content: res.msg,
            className: "register-message-error",
          })
        }
      },
    }
  );

  // 获取验证码
  const usesendVer = async () => {
    let user_mob = form.getFieldValue("user_mob");
    if (!user_mob) {
      return false;
    }
    getverCode({
      params: {
        mob: user_mob,
        type: "FORGET",
      },
    });
    setcountdjs(false);
    const timer = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount === 0) {
          clearInterval(timer);
          setcountdjs(true);
          return 60;
        }
        return prevCount - 1;
      });
    }, 1000);
    // 清除定时器
    return () => clearInterval(timer);
  };


  const { run: forgetPush } = useFetch(base.POST_FORGET, {},
    {
      manual: true,
      onResponse(res) {
        if (res['code'] === 200) {
          message.success({
            content: "修改成功",
            className: "forget-message-success",
          })
          navigate("/login");
        } else {
          message.error({
            content: "修改失败，" + res['msg'],
            className: "forget-message-error",
          })
        }
      },
    }
  );

  const onFinish = (values: Record<string, any>) => {
    forgetPush({
      method: "post",
      data: {
        captcha: values.captcha, //验证码
        user_mob: values.user_mob, //用户手机号
        user_pwd: MD5(values.user_pwd).toString() //修改的密码
      }
    });
  };

  const onFinishFailed = () => {
    console.log("Failed:");
  };

  return (
    <View id="Forget">
      <Layout className="lmc-layout-style">
        <Headers></Headers>
        <Content className="lmc-content-style">
          <View className="lmc-content-parent">
            <p className="lmc-forget-title">忘记密码</p >
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 24 }}
              style={{ maxWidth: 900 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              labelAlign="left"
            >
              <Form.Item
                name="user_mob"
                label="手机"
                rules={[
                  { required: true, message: "请输入您的手机号码!" },
                  {
                    pattern: /^1[3456789]\d{9}$/,
                    message: "请输入正确的手机号",
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  style={{ width: "100%" }}
                  placeholder="请输入手机号"
                />
              </Form.Item>
              <Form.Item label="验证码" required>
                <Row gutter={8}>
                  <Col span={14}>
                    <Form.Item
                      required
                      name="captcha"
                      noStyle
                      rules={[
                        { required: true, message: "请输入您的验证码!" },
                        {
                          pattern: /^\d{6}$/,
                          message: "请输入正确的验证码",
                        },
                      ]}
                    >
                      <Input
                        autoComplete="off"
                        placeholder="请输入验证码"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    {countdjs ? (
                      <Button
                        type="primary"
                        onClick={usesendVer}
                        style={{ width: "100%" }}
                      >
                        获取
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        disabled
                        className="lmc-code"
                        style={{ width: "100%" }}
                      >
                        {count}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                name="user_pwd"
                label="密码"
                rules={[
                  {
                    required: true,
                    message: "请输入您的密码!",
                  },
                  {
                    min: 5,
                    message: '密码至少五位',
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="请输入密码" />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="确认密码"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "请确认您的密码!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("user_pwd") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("您输入的两个密码不匹配!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="请输入确认密码" />
              </Form.Item>
              <Form.Item>
                <Button
                  className="lmc-button-submit"
                  htmlType="submit"
                  type="primary"
                >
                  确定
                </Button>
              </Form.Item>
              <Form.Item className="lmc-button-already">
                已有账号，
                <Link to="/sign/login">
                  <Button type="link">返回登录</Button>
                </Link>
              </Form.Item>
            </Form>
          </View>
        </Content>
      </Layout>
    </View>
  );
};

export default Forget;