// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#headers .lmc-header-style {
  background-color: #fff !important;
  background-image: linear-gradient(to bottom, #DAE0E8, rgba(240, 241, 245, 0.5));
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 10px;
  height: 100px;
  line-height: 100px;
}
#headers .lmc-header-style .lmc-header-main {
  display: flex;
  text-align: center;
  align-items: center;
  height: 100px;
}
#headers .lmc-header-style .lmc-header-main .lmc-icon-logo {
  width: 48px;
  height: 42px;
  margin-top: -40px;
}
#headers .lmc-header-style .lmc-header-main .lmc-icon-logo img {
  width: 100%;
  height: 100%;
}
#headers .lmc-header-style .lmc-header-main .lmc-icon-title {
  font-size: 24px;
  margin: 0px 5px 0px 10px;
  line-height: 100px;
}
#headers .lmc-header-style .lmc-header-main .lmc-icon-beat {
  width: 50px;
  line-height: 22px;
  font-size: 12px;
  color: #FFFFFF;
  background: #C6241D;
  border-radius: 6px 0px 6px 6px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/style/headers.less"],"names":[],"mappings":"AAAA;EAEI,iCAAA;EACA,+EAAA;EACA,2BAAA;EACA,4BAAA;EACA,0BAAA;EACA,aAAA;EACA,kBAAA;AAAJ;AARA;EAUM,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;AACN;AAdA;EAeQ,WAAA;EACA,YAAA;EACA,iBAAA;AAER;AAnBA;EAmBU,WAAA;EACA,YAAA;AAGV;AAvBA;EAwBQ,eAAA;EACA,wBAAA;EACA,kBAAA;AAER;AA5BA;EA6BQ,WAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,mBAAA;EACA,8BAAA;AAER","sourcesContent":["#headers {\n  .lmc-header-style {\n    background-color: #fff !important;\n    background-image: linear-gradient(to bottom, #DAE0E8, rgba(240, 241, 245, 0.5));\n    background-position: bottom;\n    background-repeat: no-repeat;\n    background-size: 100% 10px;\n    height: 100px;\n    line-height: 100px;\n    .lmc-header-main {\n      display: flex;\n      text-align: center;\n      align-items: center;\n      height: 100px;\n      .lmc-icon-logo {\n        width:48px;\n        height:42px;\n        margin-top: -40px;\n        img {\n          width: 100%;\n          height: 100%;\n        }\n      }\n      .lmc-icon-title {\n        font-size: 24px;\n        margin:0px 5px 0px 10px;\n        line-height: 100px;\n      }\n      .lmc-icon-beat {\n        width: 50px;\n        line-height: 22px;\n        font-size: 12px;\n        color: #FFFFFF;\n        background: #C6241D;\n        border-radius: 6px 0px 6px 6px;\n      }\n    }\n  };\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
