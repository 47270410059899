import React, { FC } from "react";
import { Link,useNavigate } from "react-router-dom";
import { View, Images } from "../../components";
import { Form, Input, Button, Layout, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { LMC_LOGO_IMG, LMC_SIGN_BG_IMG } from "../../assets/images";
import { MD5 } from "crypto-js";
import { base } from "../../service";
import { useFetch } from "../../hooks";
import "./style/index.less";
const { Footer, Sider } = Layout;

export interface LoginProps {}


const Login: FC<LoginProps> = () => {
  const navigate = useNavigate();
  message.config({
    top: 8,
    duration: 1,
    maxCount: 1,
    rtl: true,
    prefixCls: "login-message",
  });

  // 登录
  const { run: loginPush } = useFetch(
    base.POST_LOGIN,
    {},
    {
      manual: true,
      onResponse(res) {
        if (res.code === 200) {
          message.success({
            content: "登录成功",
            className: "login-message-success",
          });
          localStorage.setItem("auth:token", res["data"] as string);
          navigate("/");
        } else {
          message.error({
            content: "登录失败，" + res.msg,
            className: "login-message-error",
          });
        }
      },
    }
  );

  const onFinish = (values: Record<string, string>) => {
    // loginPush({
    //   method: "post",
    //   data: {
    //     user_name: values.user_name,
    //     user_pwd: MD5(values.user_pwd).toString(),
    //   },
    // });
    localStorage.setItem("auth:token", values.user_name);
    localStorage.setItem("accountName", values.user_name)
    navigate("/");
  };
  return (<View id="Login">
    <Layout>
      <Layout>
        <Sider className="lmc-login-left" width="50%">
          <View className="lmc-login-text">
            <span className="lmc-welcome"> 嗨~ </span>
            <span className="lmc-welcome"> 欢迎使用联盟仓--后台管理 ！</span>
          </View>
          <View className="lmc-welocom-img">
            <Images src={LMC_SIGN_BG_IMG} />
          </View>
        </Sider>
        <Sider className="lmc-login-right" width="50%">
            <View className="lmc-login-logo">
              <View className="lmc-icon-img">
                <Images src={LMC_LOGO_IMG} />
              </View>
              <View className="lmc-icon-title">联盟仓</View>
              <View className="lmc-icon-beta">新升级</View>
            </View>
            <View className="lmc-login-form-content">
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <p className="lmc-right-text">欢迎登录</p >
                <Form.Item
                  label="账户名:"
                  name="user_name"
                  rules={[{ message: "账户名输入错误，请重新填写！" }]}
                >
                  <Input
                    autoComplete="off"
                    prefix={<UserOutlined style={{ fontSize: "20px" }} />}
                    style={{ height: "40px" }}
                    className="lmc-right-from-input"
                    placeholder="请输入您的用户名"
                  />
                </Form.Item>
                <Form.Item
                  label="密码:"
                  name="user_pwd"
                  rules={[{ message: "密码输入错误，请重新填写！" }]}
                  style={{ width: "100%" }}
                >
                  <Input.Password
                    prefix={<LockOutlined style={{ fontSize: "20px" }} />}
                    type="password"
                    placeholder="请输入您的密码"
                    className="lmc-right-from-input"
                    style={{ height: "40px" }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="lmc-right-from-input"
                    block
                    style={{
                      height: "40px",
                      fontSize: "16px",
                      marginTop: "30px",
                    }}
                  >
                    登录
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Link to="/sign/forget">
                    <Button type="link" style={{ float: "right" }}>
                      忘记密码
                    </Button>
                  </Link>
                </Form.Item>
              </Form>
            </View>
          </Sider>
      </Layout>
      <Footer className="lmc-login-footer">©联盟仓</Footer>
    </Layout>
    {/* <LayoutIndex>kkk</LayoutIndex> */}
  </View>)
}

export default Login;