import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import {
  TeamOutlined,
  AppstoreAddOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';
import { View, Images, Text } from "../../components";
import { Layout, Menu, Button, theme, MenuProps, Affix } from 'antd';
import { LMC_LOGO_IMG } from "../../assets/images";
import './styles/index.less';
const { Header, Sider, Content } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('会员管理', '/', <TeamOutlined />),
  getItem('功能管理', '/funtionManage', <AppstoreAddOutlined />),
  getItem('会员审核', '/memberReview', <AppstoreAddOutlined />),
  getItem('快递审核', '/expressDeliveryReview', <AppstoreAddOutlined />),
  getItem('实名审核', '/realNameAuditing', <AppstoreAddOutlined />),
  getItem('服务市场', '/serveMarket', <AppstoreAddOutlined />, [
    getItem('类目管理', '/serveMarket/classsView', <AppstoreAddOutlined />),
    getItem('服务商管理', '/serveMarket/serveView', <AppstoreAddOutlined />),
  ]),

];
const Home: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState('/');//SIDE MEUN 默认文件
  const navigate = useNavigate();
  const location  = useLocation();//监听路由变化

  /**
   * 拦截未登录
   */
  const authorization = localStorage.getItem("auth:token");
  if (!authorization) {
    window.top?.location.assign('/sign/login');
  }
  //获取登录账户名称
  const accountName = localStorage.getItem("accountName") ? localStorage.getItem("accountName") : null;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const meunClick = (e: { key: string }) => {
    setCurrent(e.key);
    navigate(e.key);
  }

  const MeunLogo = (bool: Boolean) => (
    bool ?
      <Images className="meun-logo" src={LMC_LOGO_IMG} /> :
      <View className="meun-long">
        <Images className="meun-logo" src={LMC_LOGO_IMG} />
        <Text className="meun-title">联盟仓-管理平台</Text>
      </View>
  )

  const onChangeOut = () => {
    localStorage.clear()
    window.top?.location.assign('/sign/login')
  }

  useEffect(() => {
      setCurrent(location.pathname as string)
  }, [location])

  return (
    <View id="layout-home">
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <View className="demo-logo-vertical" >
            {MeunLogo(collapsed)}
          </View>
          <Menu
            theme="dark"
            mode="inline"
            triggerSubMenuAction="click"
            items={items}
            onClick={meunClick}
            selectedKeys={[current]}
          />
          <Affix style={{ position: 'absolute', bottom: 50, left: collapsed ? 5 : 60 }}>
            <Button onClick={onChangeOut} shape="round" className="btn-out">退出</Button>
          </Affix>
        </Sider>
        <Layout>
          <Header style={{ padding: 0, background: colorBgContainer }}>
            <View className="layout-header">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                  color: '#fff',
                }}
              />
              <Text className="account-title">
                {accountName}
              </Text>
            </View>
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              height: document.documentElement.clientHeight - 100,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflowY: 'scroll'
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </View>
  );
};

export default Home;