/*
 * @Author: Yoneyy (y.tianyuan)
 * @Date: 2024-01-16 15:44:18
 * @Last Modified by: Yoneyy (y.tianyuan)
 * @Last Modified time: 2024-01-16 18:11:49
 */

export const LMC_LOGO_IMG = require('./lmc-logo.png');

export const LMC_SIGN_BG_IMG = require('./lmc-sign-bg.png');

export const LMC_BASE_LOGO_IMG = require('./lmc-base-logo.png');
// 自助打印
export const LMC_JM_IMG = require('./lmc-jm-shop.png');

export const LMC_PRINT_JD = require('./lmc-print-jd.png');

export const LMC_JD_SHOP_IMG = require('./lmc-jd-shop.png');

export const LMC_TAOBAO_IMG = require('./lmc-taobao.jpg');

export const LMC_TMALL_IMG = require('./lmc-tmall.jpg');
//首页 banner
export const LMC_HOME_IMG = require('./lmc-home-banner.jpeg');

export const LMC_HOME_BLUE_IMG = require('./lmc-home-blue.png');

export const LMC_HOME_PUR_IMG = require('./lmc-home-purple.png');
