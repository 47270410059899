/*
 * @Author: Yoneyy (y.tianyuan)
 * @Date: 2022-03-02 22:10:07
 * @Last Modified by: Yoneyy (y.tianyuan)
 * @Last Modified time: 2022-08-24 09:55:18
 */

import { useEffect } from 'react';

/**
 * useMount
 * @param callback 传入的函数
 * @author yoneyy (y.tianyuan)
 */
const useMount = (
  callback: (...rest: unknown[]) => void,
  deps: React.DependencyList = []
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => callback(), deps);
}


export default useMount;
