// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Forget .lmc-layout-style {
  overflow: 'hidden';
  background-color: #FFFFFF;
  position: relative;
}
#Forget .lmc-content-style .lmc-content-parent {
  top: 100%;
  left: 40%;
  position: absolute;
}
#Forget .lmc-content-style .lmc-forget-title {
  font-size: 26px;
  font-weight: 600;
  color: rgba(21, 21, 21, 0.8);
  line-height: 0px;
  margin: 60px 0 60px 0;
}
#Forget .lmc-content-style form .lmc-button-submit {
  width: 348px;
  height: 35px;
  background: #3A7FFF;
  margin-top: 60px;
}
#Forget .lmc-content-style form .lmc-button-already {
  text-align: right;
}
#Forget .lmc-content-style form .lmc-code {
  color: #fff;
  background: #827f7f;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/style/forget.less"],"names":[],"mappings":"AAAA;EAEI,kBAAA;EACA,yBAAA;EACA,kBAAA;AAAJ;AAJA;EAQM,SAAA;EACA,SAAA;EACA,kBAAA;AADN;AATA;EAaM,eAAA;EACA,gBAAA;EACA,4BAAA;EACA,gBAAA;EACA,qBAAA;AADN;AAhBA;EAqBQ,YAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;AAFR;AAtBA;EA2BQ,iBAAA;AAFR;AAzBA;EA8BQ,WAAA;EACA,mBAAA;AAFR","sourcesContent":["#Forget {\n  .lmc-layout-style {\n    overflow: 'hidden';\n    background-color: #FFFFFF;\n    position: relative;\n  }\n  .lmc-content-style {\n    .lmc-content-parent {\n      top: 100%;\n      left: 40%;\n      position: absolute;\n    }\n    .lmc-forget-title {\n      font-size: 26px;\n      font-weight: 600;\n      color: rgba(21,21,21,0.8);\n      line-height: 0px;\n      margin: 60px 0 60px 0;\n    }\n    form {\n      .lmc-button-submit {\n        width: 348px;\n        height: 35px;\n        background: #3A7FFF;\n        margin-top: 60px;\n      }\n      .lmc-button-already {\n        text-align: right;\n      }\n      .lmc-code {\n        color: #fff;\n        background: #827f7f;\n      }\n    }\n  };\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
