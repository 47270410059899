/**
 * 存放API接⼝地址
*/

/**
 * Base Api
*/
export const base = {
  POST_LOGIN: '/user/sign/in',//登录
  GET_SMS_CODE: '/base/sms/captcha',
  POST_FORGET: '/user/forget/pwd',
  PUT_UPLOAD: '/base/upload/single',//单个上传文件
}

/**
 * User Api
*/
export const user = {

}

/**
 *  VIP Api
*/
export const vip = {
  POST_VIP_CREATE: '/vip/create', //创建会员
  POST_VIP_MODIFY: '/vip/modify', //修改会员
  GET_VIP_LIST: '/vip/list',//获取会员列表
  GET_VIP_DETAIL: '/vip/detail',//获取详情页
  POST_VIP_REMOVE: '/vip/remove',//删除会员
}

/**
 * FEAT Api
*/
export const feat = {
  GET_FEAT_LIST: '/feat/list',//获取系统功能列表
  POST_FEAT_CREATE: '/feat/create',//新增系统功能
  GET_FEAT_DETAIL: '/feat/detail',//功能详情
  POST_FEAT_MODIFY: '/feat/modify',//功能修改
}

/**
 * express Api
*/
export const express = {
  GET_EXPRESS_LIST: '/express/provider/vos/list',//获取快递服务商授权信息列表
  POST_EXPRESS_REJECT: '/express/provider/vos/reject', //审核驳回快递服务商授权
  POST_EXPRESS_APPROVE: '/express/provider/vos/approve', //审核通过快递服务商授权
}