import React, { FC } from "react";
import { Layout } from "antd";
import { View, Images } from "../../../components";
import { LMC_LOGO_IMG } from "../../../assets/images";
import "../style/headers.less";

const { Header } = Layout;
const Headers: FC<{}> = () => {
  return (
    <View id="headers">
      <Header className="lmc-header-style">
        <View className="lmc-header-main">
          <View className="lmc-icon-logo">
            <Images src={LMC_LOGO_IMG} />
          </View>
          <View className="lmc-icon-title">联盟仓</View>
          <View className="lmc-icon-beat">新升级</View>
        </View>
      </Header>
    </View>
  );
};

export default Headers;
