/*
 * @Author: Yoneyy (y.tianyuan)
 * @Date: 2022-06-29 19:21:59
 * @Last Modified by: Yoneyy (y.tianyuan)
 * @Last Modified time: 2022-07-04 17:24:43
 */

import React, { forwardRef, ForwardRefRenderFunction } from "react";

export interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  defaultSource?: string;
};

const Images: ForwardRefRenderFunction<HTMLImageElement, ImageProps> = ({ src, defaultSource, ...props }, ref) => (
  <img ref={ref} alt={props.alt} src={src ?? defaultSource} {...props} onError={e => {
    const image = e.target as HTMLImageElement;
    image.src = defaultSource ?? '';
    image.onerror = null;
    return null;
  }} />
);

export default forwardRef(Images);